import { useSelector, useDispatch } from "react-redux";
import useFetch from "../../utils/useFetch";
import { setList, setDetail } from "./store";

const useAccountCampaigns = () => {
  const { list, detail, analytics } = useSelector(
    (state) => state.accountCampaigns
  );

  const dispatch = useDispatch();
  const { get, post, put } = useFetch();

  const endpoint = "account-campaigns";

  const fetchList = async (params) => {
    const response = await get({
      endpoint,
      isPrivate: true,
      params,
    });
    response && dispatch(setList(response));
  };

  const fetchDetail = async (id) => {
    const response = await get({ endpoint: `${endpoint}/${id}` });
    dispatch(setDetail(response));
  };

  const updateDetail = async (id, body) => {
    const response = await put({
      endpoint,
      id,
      body,
    });
    response && dispatch(setDetail(response));
  };

  const createCampaign = async (body) => {
    return await post({
      endpoint,
      body,
    });
  };

  return {
    campaignList: list,
    campaign: detail,
    fetchCampaignList: fetchList,
    fetchCampaign: fetchDetail,
    setCampaign: (campaign) => dispatch(setDetail(campaign)),
    updateCampaign: updateDetail,
    createCampaign,
  };
};

export default useAccountCampaigns;
