export default [
  {
    question: "¿Cuánto cuesta empezar a usar Wubalu?",
    answer:
      "¡Empezar es gratis! Todos los meses tienes 10 stories gratis para que puedas disfrutar de toda la experiencia.",
  },
  {
    question: "¿Hay algún compromiso de permanencia?",
    answer:
      "¡Absolutamente no! En cualquier momento puedes darte de baja sin penalización.",
  },
  {
    question: "¿Cuándo puedo empezar?",
    answer:
      "¡Ahora mismo! En menos de cinco minutos puede tener tu cuenta operativa.",
  },
  {
    question: "¿Cómo se comprueba que las stories sean correctas?",
    answer:
      "Estamos a la última, utilizamos la inteligencia artificial para validar que todo el proceso se haya ejecutado correctamente, si la story no es correcta, no hay premio.",
  },
  {
    question:
      "¿Puedo usar el contenido publicado por los usuarios para fines comerciales propios?",
    answer:
      "¡Claro que sí! Los usuarios aceptan unos términos y condiciones que ceden al restaurante todos los derechos de las fotos.",
  },
];
