import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import useUi from "../ui/useUi";

import { setUser, setIsLoaded, userLogout } from "./store";

let refreshInterval;

const useAuth = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { showLoader, hideLoader } = useUi();

  const signup = async (data) => {
    showLoader();
    const response = await fetch(`${process.env.REACT_APP_API}/users/signup`, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(data),
    });
    hideLoader();
    const res = await response.json();
    if (res.id) {
      login(data);
    } else {
      toast.error("Email ya está en uso");
    }
  };

  const login = async (data) => {
    showLoader();
    const body = {
      client_id: process.env.REACT_APP_AUTH_ID,
      client_secret: process.env.REACT_APP_AUTH_SECTRET,
      grant_type: "password",
      username: data.email,
      password: data.password,
    };

    const response = await fetch(`${process.env.REACT_APP_AUTH_API}/token`, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(body),
    });
    hideLoader();
    const res = await response.json();
    if (res.access_token) {
      localStorage.setItem("wubalu_restaurants_access_token", res.access_token);
      localStorage.setItem(
        "wubalu_restaurants_refresh_token",
        res.refresh_token
      );
      localStorage.setItem("wubalu_restaurants_token_backend", "django");
      me();
    } else {
      toast.error("Credenciales inválidas.");
      logout();
    }
  };

  const requestResetPassword = async (email) => {
    await fetch(`${process.env.REACT_APP_AUTH_API}/password-reset/`, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(email),
    });
  };

  const resetPassword = async (body, cb) => {
    const response = await fetch(
      `${process.env.REACT_APP_AUTH_API}/password-reset/confirm/`,
      {
        method: "post",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );
    const data = await response.json();
    if (data.status === "OK") {
      // toast.success(t("auth.password-reset"));
      cb();
    }
  };

  const me = async () => {
    try {
      const token = localStorage.wubalu_restaurants_access_token;
      if (token && token !== "undefined") {
        showLoader();
        const response = await fetch(`${process.env.REACT_APP_API}/users/me/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        hideLoader();
        // REFRESH CADA 1H - HABRÁ QUE CAMBIARLO A FUTURO
        clearInterval(refreshInterval);
        refreshInterval = setInterval(function () {
          me();
        }, 3600000);
        if (response.status === 200) {
          const res = await response.json();
          dispatch(setUser(res));
        } else if (localStorage.wubalu_restaurants_refresh_token) {
          if (localStorage.wubalu_restaurants_token_backend === "django") {
            // refreshToken()
          } else {
            convertToken({
              token: localStorage.wubalu_restaurants_refresh_token,
              action: "refresh_token",
              backend: localStorage.wubalu_restaurants_token_backend,
            });
          }
        }
      }
    } catch (e) {
    } finally {
      dispatch(setIsLoaded(true));
    }
  };

  const refreshToken = async () => {
    const data = {
      grant_type: "refresh_token",
      client_id: process.env.REACT_APP_AUTH_ID,
      client_secret: process.env.REACT_APP_AUTH_SECTRET,
      token: localStorage.wubalu_restaurants_refresh_token,
    };
    const response = await fetch(
      `${process.env.REACT_APP_AUTH_API}/refresh-token`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(data),
      }
    );
    const res = await response.json();
    if (res.access_token) {
      localStorage.setItem("wubalu_restaurants_access_token", res.access_token);
      localStorage.setItem(
        "wubalu_restaurants_refresh_token",
        res.refresh_token
      );
      localStorage.setItem("wubalu_restaurants_token_backend", "django");
      me();
    } else {
      logout();
    }
  };

  const convertToken = async ({
    token,
    action = "convert_token",
    backend = "facebook",
  }) => {
    showLoader();
    const data = {
      grant_type: action,
      client_id: process.env.REACT_APP_AUTH_ID,
      client_secret: process.env.REACT_APP_AUTH_SECTRET,
      backend,
      token,
    };
    const response = await fetch(
      `${process.env.REACT_APP_AUTH_API}/oauth-signup`,
      {
        method: "post",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    hideLoader();
    const res = await response.json();
    if (res.access_token) {
      localStorage.setItem("wubalu_restaurants_access_token", res.access_token);
      localStorage.setItem(
        "wubalu_restaurants_refresh_token",
        res.refresh_token
      );
      localStorage.setItem("wubalu_restaurants_token_backend", backend);
      await me();
      return res;
    } else {
      logout();
    }
  };

  const logout = () => {
    localStorage.removeItem("wubalu_restaurants_access_token");
    localStorage.removeItem("wubalu_restaurants_refresh_token");
    localStorage.removeItem("wubalu_restaurants_token_backend");
    clearInterval(refreshInterval);
    dispatch(userLogout());
  };

  const deactivateAccount = async () => {
    const token = localStorage.wubalu_restaurants_access_token;
    const response = await fetch(
      `${process.env.REACT_APP_API}/users/deactivate-account/`,
      {
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  };

  return {
    signup,
    login,
    requestResetPassword,
    resetPassword,
    me,
    convertToken,
    logout,
    deactivateAccount,
    ...auth,
  };
};

export default useAuth;
