import { useState, useEffect } from "react";
import { Scanner } from "@yudiel/react-qr-scanner";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import useStories from "../../../models/stories/useStories";
import useAuth from "../../../models/auth/useAuth";
import useRestaurants from "../../../models/restaurants/useRestaurants";

import imageOK from "./images/ok.png";
import imageKO from "./images/ko.png";
import AutocompleteInput from "../../../components/Autocomplete";
import AndroidButton from "../../../components/appButtons/AndroidButton";
import AppleButton from "../../../components/appButtons/AppleButton";

import "./styles.scss";

const QRScan = () => {
  const { user, logout } = useAuth();
  const [showQR, setShowQR] = useState(true);
  const [showGiveReward, setShowGiveReward] = useState(false);
  const [prize, setPrize] = useState();
  const [showRerunPermission, setShowRerunPermission] = useState(false);
  const { story, fetchStory, validateStory, rejectStory, clearStory } =
    useStories();
  const {
    restaurantList,
    restaurant,
    setRestaurant,
    fetchRestaurant,
    fetchRestaurantList,
  } = useRestaurants();

  const onLoadStory = async () => {
    setPrize(story.campaign.influencer_prize);
    if (story.restaurant !== restaurant.id) {
      await fetchRestaurant(story.restaurant_slug);
    }
    if (story.status === "AI_VALIDATED") {
      handleValidateStory();
    }
  };

  const handleValidateStory = async () => {
    try {
      await validateStory(story.id);
      setShowGiveReward(true);
    } catch (e) {
      if (e.status === 403) {
        toast.error("El QR del cliente no pertenece a este restaurante.");
      } else toast.error("Error al validar.");
    } finally {
      clearStory();
      setShowQR(true);
    }
  };

  const handleRejectStory = async () => {
    try {
      await rejectStory(story.id);
      toast.success("Story rechazada correctamente.");
    } catch (e) {
      toast.error("ERROR! volver a rechazar.");
    } finally {
      clearStory();
      setShowQR(true);
    }
  };

  const handleScanOK = async (result) => {
    const storyJson = JSON.parse(result);
    await fetchStory(storyJson.id);
    setShowQR(false);
  };

  useEffect(() => {
    story.id && onLoadStory();
  }, [story]);

  useEffect(() => {
    if (!restaurant.id && restaurantList.length > 0) {
      setRestaurant(restaurantList[0]);
    }
  }, [restaurantList, restaurant]);

  useEffect(() => {
    user.restaurant_worker && fetchRestaurantList();
  }, [user]);

  if (showGiveReward) {
    return (
      <div className="QRScan QRScan--reward">
        <div className="QRScan__content">
          <img src={imageOK} className="QRScan__status-image" />
          <h1>¡Este cliente se ha ganado su premio!</h1>
          <div className="QRScan__text">
            Por favor, sírvele a este cliente{" "}
            <b>
              <u>{prize} gratis</u>
            </b>
          </div>
        </div>
        <Button
          variant="contained"
          onClick={() => {
            setShowQR(true);
            setShowGiveReward(false);
          }}
        >
          Volver a escanear
        </Button>
      </div>
    );
  }

  if (user && !user.restaurant_worker) {
    return (
      <div className="QRScan">
        <div className="QRScan__content">
          <h1>No tienes acceso.</h1>
          <div className="QRScan__text">
            Por favor, ponte en contacto con tu administrador.
          </div>
        </div>
        <Button variant="outlined" onClick={logout}>
          Cerrar sesión
        </Button>
      </div>
    );
  }

  if (showRerunPermission) {
    return (
      <div className="QRScan">
        <div className="QRScan__content">
          <h1>Validador de Stories</h1>
          <div className="QRScan__text">
            Has rechazado el permiso de acceso a la cámara y por lo tanto no
            puedes validar QRs.
          </div>
          <div className="QRScan__text">
            Aquí tienes un enlace que te explica como volver a activarlo en cada
            uno de los navegadores. Una vez reactivado, tendrás que recargar la
            página.{" "}
            <a
              target="_blank"
              href="https://support.onemob.com/hc/en-us/articles/360037342154-How-do-I-grant-permission-for-Camera-and-Microphone-in-my-web-browser#:~:text=Chrome%20Browser,click%20either%20Camera%20or%20Microphone."
            >
              Guía de activación de permiso.
            </a>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="QRScan">
      <div className="QRScan__content">
        <h1>Validador de Stories</h1>
        {showQR ? (
          <>
            <div className="QRScan__select-restaurant">
              {restaurantList?.length > 0 && (
                <AutocompleteInput
                  value={restaurant}
                  options={restaurantList || []}
                  onChange={setRestaurant}
                  getOptionLabel={(option) => option.name}
                />
              )}
            </div>
            {restaurant.participation_requirement ? (
              <div className="QRScan__text">
                Si el comensal ha cumplido el{" "}
                <b>
                  requisito: <u>{restaurant.participation_requirement}</u>
                </b>
                , escanea el código QR para verificar que la story es correcta.
              </div>
            ) : (
              <div className="QRScan__text">
                Escanea el código QR para verificar que la story es correcta.
              </div>
            )}
            <div className="QRScan__qr-wrapper">
              <Scanner
                onResult={handleScanOK}
                onError={(error) => {
                  toast.error("ERROR! No se pudo leer QR.");
                  if (error.message === "Permission denied") {
                    setShowQR(false);
                    setShowRerunPermission(true);
                  }
                }}
                enabled={showQR}
              />
            </div>
            <div className="QRScan__apps">
              <div className="QRScan__apps__title">
                ¿Todavía no tienes la app?
              </div>
              <div className="QRScan__apps__text">
                Descárgate nuestras apps para validar desde tu móvil sin
                necesidad de hacer login cada vez. Es mucho más fácil y cómodo.
              </div>
              <div className="QRScan__apps__buttons">
                <AndroidButton />
                <AppleButton />
              </div>
            </div>
          </>
        ) : (
          <>
            {" "}
            {story && story.status === "VALIDATED" && (
              <>
                <img src={imageKO} className="QRScan__status-image" />
                <div className="QRScan__validated">NO HAY PREMIO.</div>
                <div className="QRScan__text">
                  Esta story ya ha sido validada.
                </div>
                <Button variant="contained" onClick={() => setShowQR(true)}>
                  Volver a escanear
                </Button>
              </>
            )}
            {story && story.status === "REJECTED" && (
              <>
                <img src={imageKO} className="QRScan__status-image" />
                <div className="QRScan__rejected">NO HAY PREMIO.</div>
                <div className="QRScan__text">
                  Esta story ya ha sido rechazada.
                </div>
                <Button variant="contained" onClick={() => setShowQR(true)}>
                  Volver a escanear
                </Button>
              </>
            )}
            {story && story.status === "CREATED" && (
              <>
                <div className="QRScan__text">
                  Revisa bien que la story cumpla todos los requisitos:
                </div>
                <div className="QRScan__req">
                  <div className="QRScan__req__item">
                    <CheckCircleIcon color="success" />
                    Se menciona a @{restaurant.ig_account}
                  </div>
                  <div className="QRScan__req__item">
                    <CheckCircleIcon color="success" />
                    Creada por @{story.ig_account}
                  </div>
                  <div className="QRScan__req__item">
                    <CheckCircleIcon color="success" />{" "}
                    {restaurant.participation_requirement}
                  </div>
                </div>
                <div className="QRScan__image">
                  <img src={story.image} />
                </div>
              </>
            )}
          </>
        )}
      </div>
      {!showQR && story && story.status === "CREATED" && (
        <div className="QRScan__actions">
          <Button variant="contained" color="error" onClick={handleRejectStory}>
            Rechazar
          </Button>
          <Button variant="contained" onClick={handleValidateStory}>
            Validar
          </Button>
        </div>
      )}
    </div>
  );
};

export default QRScan;
