import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const AutocompleteInput = ({
  onChange,
  options,
  placeholder,
  value,
  getOptionLabel,
}) => {
  return (
    <Autocomplete
      value={value}
      getOptionLabel={getOptionLabel}
      className="Autocomplete"
      disablePortal
      id="combo-box"
      options={options}
      disableClearable
      sx={{ width: 300 }}
      onChange={(_, v) => onChange(v)}
      renderInput={(params) => (
        <TextField {...params} placeholder={placeholder} />
      )}
    />
  );
};

export default AutocompleteInput;
