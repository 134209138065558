import { Controller } from "react-hook-form";
import Switch from "./index";

const FormSwitch = ({
  control,
  name,
  label,
  disabled,
  helperText,
  onChange,
}) => {
  const handleChange = ({ name, value, onFieldChange }) => {
    onFieldChange(value);
    onChange && onChange(name, value);
  };
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <Switch
          label={label ?? ""}
          name={field.name}
          checked={field.value ?? ""}
          helperText={helperText}
          onChange={(value) =>
            handleChange({
              value,
              name: field.name,
              onFieldChange: field.onChange,
            })
          }
          error={fieldState?.error?.message}
          disabled={disabled}
        />
      )}
    />
  );
};

export default FormSwitch;
