import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
  detail: {},
};

export const counterSlice = createSlice({
  name: "accountCampaigns",
  initialState,
  reducers: {
    setDetail: (state, action) => {
      state.detail = action.payload;
    },
    setList: (state, action) => {
      state.list = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setList, setDetail, setAnalytics } = counterSlice.actions;

export default counterSlice.reducer;
