import useAuth from "../../models/auth/useAuth";
import Unauthorized from "../Unauthorized";

const AdminRoute = ({ children }) => {
  const {
    user: { restaurant_worker },
  } = useAuth();

  if (restaurant_worker && restaurant_worker.is_admin) return children;

  return <Unauthorized />;
};

export default AdminRoute;
