import playstore from "./playstore.png";

const AndroidButton = () => {
  return (
    <div className="AndroidButton">
      <a href="https://play.google.com/store/apps/details?id=com.wubalu">
        <img src={playstore} alt="Download on Play Store" />
      </a>
    </div>
  );
};
export default AndroidButton;
