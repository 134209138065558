import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loaderCount: 0,
};

export const slice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    showLoader: (state) => {
      state.loaderCount += 1;
    },
    hideLoader: (state) => {
      state.loaderCount -= 1;
    },
  },
});

// Action creators are generated for each case reducer function
export const { showLoader, hideLoader } = slice.actions;

export default slice.reducer;
