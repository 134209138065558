import { useDispatch, useSelector } from "react-redux";

import { showLoader, hideLoader } from "./store";

const useUi = () => {
  const { loaderCount } = useSelector((state) => state.ui);
  const dispatch = useDispatch();

  return {
    showLoader: () => dispatch(showLoader()),
    hideLoader: () => dispatch(hideLoader()),
    isLoaderVisible: loaderCount > 0,
  };
};

export default useUi;
