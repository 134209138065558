import * as yup from "yup";

export function buildValidationSchema(view) {
  if (view === "signup") {
    return yup.object().shape({
      email: yup
        .string()
        .email("Email no válido")
        .label("Email")
        .required("Campo requerido"),
      password: yup
        .string()
        .label("Contraseña")
        .min(6, "La contraseña debe tener al menos 6 caracteres")
        .required("Campo requerido"),
      repeatPassword: yup
        .string()
        .label("Repetir contraseña")
        .oneOf([yup.ref("password"), null], "Las contraseñas deben coincidir")
        .required("Campo requerido"),
    });
  } else {
    return yup.object().shape({
      email: yup
        .string()
        .email("Email no válido")
        .label("Email")
        .required("Campo requerido"),
      password: yup.string().label("Contraseña").required("Campo requerido"),
    });
  }
}
