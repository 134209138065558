import PriceChart from "../../containers/PriceChart";
import freeIcon from "./images/free.svg";
import costsIcon from "./images/costs.svg";
import invoiceIcon from "./images/invoice.svg";
import paymentsIcon from "./images/payments.svg";
import ratesIcon from "./images/rates.svg";
import Button from "@mui/material/Button";

import "./styles.scss";

const Pricing = () => {
  return (
    <div className="PricingPage">
      <h1>Listado de precios</h1>
      <div className="PricingPage__box">
        <div className="PricingPage__title">
          Suscripción mensual <span>en función del uso por restaurante.</span>
        </div>
        <div className="PricingPage__upper">
          <div className="PricingPage__upper-1">
            Confiamos plenamente en el valor que aportamos a tu negocio. Es por
            eso que nuestras tarifas están diseñadas para que solo pagues cuando
            obtienes resultados reales.
            <div className="PricingPage__upper-1__tip">
              Sin éxito, no pagas. Así de sencillo.
            </div>
          </div>
          <div className="PricingPage__upper-2">
            <div className="PricingPage__upper-2__icon">
              <img src={freeIcon} />
            </div>
            <div>
              <div className="PricingPage__upper-2__title">
                ¡Empieza Gratis!
              </div>
              <div className="PricingPage__upper-2__text">
                Todos los meses te regalamos las diez primeras stories en cada
                uno de tus restaurantes. Esto te permite probar nuestra
                plataforma <b>totalmente gratis y sin ningún riesgo.</b>
              </div>
            </div>
          </div>
        </div>
        <div className="PricingPage__chart">
          <div className="PricingPage__chart__title">
            Tabla de <span>precios</span>
          </div>
          <div className="PricingPage__chart__subtitle">
            Revisa nuestra tabla de precios para entender como calculamos los
            costes mensuales.
          </div>
          <PriceChart />
          <div className="PricingPage__chart__demo">
            ¿Tienes dudas?
            <a href="mailto:hello@wubalu.com">
              <Button variant="contained">Envianos un email</Button>
            </a>
          </div>
        </div>
        <div className="PricingPage__lower">
          <div className="PricingPage__paragraph">
            <div className="PricingPage__paragraph__icon">
              <img src={paymentsIcon} />
            </div>
            <div className="PricingPage__paragraph__title">
              Pago por Resultados
            </div>
            <div className="PricingPage__paragraph__text">
              Solo comenzarás a pagar a partir de la 11ª story publicada. Si no
              llegas a ese número, no tendrás ningún cargo. Nuestro sistema
              asegura que solo pagues por lo que realmente importa:{" "}
              <b>publicidad de calidad.</b>
            </div>
          </div>
          <div className="PricingPage__paragraph">
            <div className="PricingPage__paragraph__icon">
              <img src={ratesIcon} />
            </div>
            <div className="PricingPage__paragraph__title">
              Tarifa Escalonada y Transparente
            </div>
            <div className="PricingPage__paragraph__text">
              A medida que publiques más stories, nuestras tarifas se ajustan
              automáticamente. Cuanto más utilices nuestra herramienta, menor
              será el coste unitario de las stories.
            </div>
          </div>
          <div className="PricingPage__paragraph">
            <div className="PricingPage__paragraph__icon">
              <img src={invoiceIcon} />
            </div>
            <div className="PricingPage__paragraph__title">
              Una Única Factura Mensual
            </div>
            <div className="PricingPage__paragraph__text">
              Gestiona todos tus restaurantes de manera centralizada y recibe
              una sola factura mensual que refleje todo el uso acumulado.{" "}
              <b>Sin sorpresas, sin complicaciones.</b>
            </div>
          </div>
          <div className="PricingPage__paragraph">
            <div className="PricingPage__paragraph__icon">
              <img src={costsIcon} />
            </div>
            <div className="PricingPage__paragraph__title">
              Sin Costes Ocultos
            </div>
            <div className="PricingPage__paragraph__text">
              Nuestro modelo de facturación es claro y directo. Solo pagas por
              las stories verificadas y no hay cargos adicionales ocultos.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
