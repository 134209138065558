import { useEffect, useState } from "react";
import useAuth from "../../models/auth/useAuth";

import "./styles.scss";

import banner from "./images/image1.png";
import CreateAccountStep1 from "./step1";
import CreateAccountStep2 from "./step2";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";

const CreateAccount = () => {
  const { me, user } = useAuth();

  const [step, setStep] = useState(1);

  useEffect(() => {
    if (user.id) {
      setStep(2);
    }
  }, [user]);

  useEffect(() => {
    me();
  }, []);

  return (
    <div className="CreateAccount">
      <div className="CreateAccount__title">Crear cuenta</div>
      <div className="CreateAccount__box">
        <div className="CreateAccount__main">
          {user?.restaurant_worker ? (
            <>
              <div className="CreateAccount__step__title">
                Ya tienes una cuenta asociada a este usuario
              </div>
              <Link to="/admin/restaurants">
                <Button
                  className="CreateAccount__step__submit CreateAccount__step__submit--restaurants"
                  variant="contained"
                >
                  Ver mis restaurantes
                </Button>
              </Link>
            </>
          ) : (
            <>
              <div className="CreateAccount__steps">Paso {step} de 2</div>
              {step === 1 ? <CreateAccountStep1 /> : <CreateAccountStep2 />}
            </>
          )}
        </div>
        <div className="CreateAccount__banner">
          <img src={banner} />
        </div>
      </div>
    </div>
  );
};

export default CreateAccount;
