import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import banner from "./images/banner.png";

import "./styles.scss";

const RestaurantsEmptyState = ({ onClick }) => {
  return (
    <div className="RestaurantsEmptyState">
      <div className="RestaurantsEmptyState__box">
        <div className="RestaurantsEmptyState__main">
          <div className="RestaurantsEmptyState__title">
            Da de alta tu primer <span>restaurante en Wubalu.</span>
          </div>
          <div className="RestaurantsEmptyState__subtitle">
            Empieza ya a poner de moda tu restaurante generando publicidad en
            redes sociales y atrayendo nuevos clientes.
          </div>
          <Link to="create">
            <Button
              className="RestaurantsEmptyState__submit"
              variant="contained"
              onClick={onClick}
            >
              Añadir restaurante
            </Button>
          </Link>
        </div>
        <div className="RestaurantsEmptyState__banner">
          <img src={banner} />
        </div>
      </div>
    </div>
  );
};

export default RestaurantsEmptyState;
