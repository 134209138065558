import { useSelector, useDispatch } from "react-redux";
import useFetch from "../../utils/useFetch";
import { setList, setDetail, setAnalytics } from "./store";

const useRestaurants = () => {
  const { list, detail, analytics } = useSelector((state) => state.restaurants);
  const dispatch = useDispatch();
  const { get, post, put } = useFetch();

  const endpoint = "restaurants";

  const fetchList = async () => {
    const response = await get({
      endpoint,
      isPrivate: true,
    });
    response && dispatch(setList(response));
  };

  const fetchDetail = async (slug) => {
    const response = await get({ endpoint: `${endpoint}/${slug}` });
    dispatch(setDetail(response));
  };

  const updateDetail = async (slug, data) => {
    const response = await put({
      endpoint,
      id: slug,
      body: data,
    });
    response && dispatch(setDetail(response));
  };

  const fetchAnalytics = async (restaurant) => {
    const response = await get({
      endpoint: `accounts/statistics`,
      params: { restaurant },
      isPrivate: true,
    });
    response && dispatch(setAnalytics(response));
  };

  const createRestaurant = async (data) => {
    return await post({
      endpoint,
      body: data,
    });
  };

  const activateCampaign = async (id, campaign, type) => {
    return await post({
      endpoint: `${endpoint}/${id}/activate-campaign`,
      body: { campaign, type },
    });
  };

  const deactivateCampaign = async (id, campaign, type) => {
    return await post({
      endpoint: `${endpoint}/${id}/deactivate-campaign`,
      body: { campaign, type },
    });
  };

  return {
    restaurantList: list,
    restaurant: detail,
    restaurantAnalytics: analytics,
    fetchRestaurantList: fetchList,
    fetchRestaurant: fetchDetail,
    setRestaurant: (restaurant) => dispatch(setDetail(restaurant)),
    updateRestaurant: updateDetail,
    fetchAnalytics,
    createRestaurant,
    activateCampaign,
    deactivateCampaign,
  };
};

export default useRestaurants;
