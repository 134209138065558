import { Outlet } from "react-router-dom";
import useAuth from "../../models/auth/useAuth";
import AuthModal from "../AuthModal";

const AuthRoute = () => {
  const { isAuthenticated, isLoaded } = useAuth();

  if (isAuthenticated) return <Outlet />;

  if (!isLoaded) return;

  return <AuthModal />;
};

export default AuthRoute;
