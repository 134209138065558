import Button from "@mui/material/Button";
import useAccounts from "../../models/accounts/useAccounts";

import "./styles.scss";

const Billing = () => {
  const { getStripePortalUrl } = useAccounts();

  const handleClick = async () => {
    const { url } = await getStripePortalUrl();
    window.open(url, "_blank");
  };

  return (
    <div className="Billing">
      <h1>Información de pagos y facturación</h1>
      <div>
        Para cambiar vuestros datos de facturación, actualizar los métodos de
        pago o acceder a vuestras facturas, por favor, haced click en el
        siguiente botón para navegar al portal privado de clientes.
      </div>
      <Button variant="contained" onClick={handleClick}>
        Ir a mi facturación
      </Button>
    </div>
  );
};

export default Billing;
