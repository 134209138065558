import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-toastify";
import GPlaces from "../../../components/GPlaces";
import { useState } from "react";
import useRestaurants from "../../../models/restaurants/useRestaurants";
import { useNavigate } from "react-router-dom";

import "./styles.scss";

const CreateRestaurant = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const { createRestaurant } = useRestaurants();
  const [address, setAddress] = useState();
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      className="CreateRestaurant"
      PaperProps={{
        component: "form",
        onSubmit: async (event) => {
          event.preventDefault();
          try {
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const response = await createRestaurant({
              name: formJson.name,
              ...address,
              full_address: address.fullAddress,
            });
            toast.success(`Restaurante creado correctamente`);
            onClose();
            navigate(response.slug);
          } catch (e) {
            toast.error("Error al crear el restaurante");
          }
        },
      }}
    >
      <DialogTitle>Alta restaurante</DialogTitle>
      <DialogContent>
        <DialogContentText>
          El nombre del restaurante tiene que ser único, sugerimos usar la calle
          o algún otro dato distintivo. (Este nombre no se mostrará a los
          clientes)
        </DialogContentText>
        <TextField
          autoFocus
          required
          margin="dense"
          id="name"
          name="name"
          label="Nombre del restaurante"
          type="text"
          fullWidth
          variant="standard"
        />
        <GPlaces required name="address" onChange={setAddress} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancelar
        </Button>
        <Button type="submit" variant="contained">
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateRestaurant;
