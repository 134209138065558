import TextField from "@mui/material/TextField";

import "./styles.scss";

const TextInput = ({
  onChange,
  type = "text",
  name,
  label,
  required,
  placeholder,
  disabled,
  value,
  error,
  helperText,
  multiline,
}) => {
  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <TextField
      onChange={handleChange}
      type={type}
      error={!!error}
      name={name}
      label={label}
      value={value}
      helperText={error ? error : helperText}
      disabled={disabled}
      placeholder={placeholder}
      required={required}
      multiline={multiline}
      rows={4}
      autoComplete="new-password"
    />
  );
};

export default TextInput;
