import { useMemo, useRef } from "react";
import html2canvas from "html2canvas";
import { Button } from "@mui/material";
import QRCode from "react-qr-code";
import useRestaurants from "../../../../models/restaurants/useRestaurants";
import LogoImg from "../../../../assets/logo_white.svg";
import "./styles.scss";

const RestaurantDisplays = () => {
  const { restaurant } = useRestaurants();
  const qrRef1 = useRef();
  const qrRef2 = useRef();
  const qrRef3 = useRef();

  const camaignPrize = useMemo(() => {
    return restaurant.account_campaigns[0]
      ? restaurant.account_campaigns[0]?.influencer_prize
      : restaurant.brand_campaigns[0]?.influencer_prize || "";
  }, [restaurant]);

  const downloadQR = (ref) => {
    html2canvas(ref.current).then((canvas) => {
      const link = document.createElement("a");
      link.href = canvas.toDataURL();
      link.download = `${restaurant.name}_qr.png`;
      link.click();
    });
  };

  return (
    <div className="RestaurantDisplays">
      <div className="RestaurantDisplays__title">Listado de QRs</div>
      <div className="RestaurantDisplays__text">
        Estas son las tres opciones de QR que hemos analizado y mejores
        resultados están dando.
      </div>
      <div className="RestaurantDisplays__text">
        Descarga los que más te gusten y pégalos en mesas o zonas visibles de tu
        restaurante para que los clientes puedan participar al escanearlos.
      </div>
      <div className="RestaurantDisplays__tip">
        ** El nombre del restaurante aparece en la esquina inferior derecha de
        los QRs, es importante pegar los QRs en el restaurante que corresponde.
      </div>
      <div className="RestaurantDisplays__list">
        <div className="RestaurantDisplays__qr">
          <div className="RestaurantDisplays__qr__sticker" ref={qrRef1}>
            <div className="RestaurantDisplays__qr__upper">
              <div className="RestaurantDisplays__qr__upper__title">
                ¡Te invitamos a una consumición!
              </div>
              <div className="RestaurantDisplays__qr__upper__subtitle">
                Solo tienes que subir una story y mencionarnos
              </div>
              <div className="RestaurantDisplays__qr__upper__text">
                Escanea el QR para descubrir cómo.
              </div>
            </div>
            <div className="RestaurantDisplays__qr__bottom">
              <div className="RestaurantDisplays__qr__bottom__name">
                {restaurant.name}
              </div>
              <QRCode
                size={256}
                bgColor="#6854FF"
                fgColor="#ffffff"
                style={{
                  height: "auto",
                  maxWidth: "172px",
                  width: "100%",
                }}
                value={`https://wubalu.com/upload-story/${restaurant.slug}?utm_source=${restaurant.slug}&utm_content=qr4`}
                viewBox={`0 0 256 256`}
              />
              <div className="RestaurantDisplays__qr__bottom__logo">
                <img alt="wubalu" src={LogoImg} />
              </div>
            </div>
          </div>
          <div className="RestaurantDisplays__qr__download">
            <Button variant="contained" onClick={() => downloadQR(qrRef1)}>
              Descargar
            </Button>
          </div>
        </div>
        <div className="RestaurantDisplays__qr">
          <div className="RestaurantDisplays__qr__sticker" ref={qrRef2}>
            <div className="RestaurantDisplays__qr__upper">
              <div className="RestaurantDisplays__qr__upper__title">
                ¡Te invitamos a una consumición si subes una story!
              </div>
              <div className="RestaurantDisplays__qr__upper__text">
                Escanea el QR para descubrir cómo
              </div>
            </div>
            <div className="RestaurantDisplays__qr__bottom">
              <div className="RestaurantDisplays__qr__bottom__name">
                {restaurant.name}
              </div>
              <QRCode
                size={256}
                bgColor="#6854FF"
                fgColor="#ffffff"
                style={{
                  height: "auto",
                  maxWidth: "172px",
                  width: "100%",
                }}
                value={`https://wubalu.com/upload-story/${restaurant.slug}?utm_source=${restaurant.slug}&utm_content=qr5`}
                viewBox={`0 0 256 256`}
              />
              <div className="RestaurantDisplays__qr__bottom__logo">
                <img alt="wubalu" src={LogoImg} />
              </div>
            </div>
          </div>
          <div className="RestaurantDisplays__qr__download">
            <Button variant="contained" onClick={() => downloadQR(qrRef2)}>
              Descargar
            </Button>
          </div>
        </div>
        {camaignPrize && (
          <div className="RestaurantDisplays__qr">
            <div className="RestaurantDisplays__qr__sticker" ref={qrRef3}>
              <div className="RestaurantDisplays__qr__upper">
                <div className="RestaurantDisplays__qr__upper__title">
                  ¡Te invitamos a {camaignPrize.toLowerCase()}!
                </div>
                <div className="RestaurantDisplays__qr__upper__subtitle">
                  Solo tienes que subir una story y mencionarnos
                </div>
                <div className="RestaurantDisplays__qr__upper__text">
                  Escanea el QR para descubrir cómo.
                </div>
              </div>
              <div className="RestaurantDisplays__qr__bottom">
                <div className="RestaurantDisplays__qr__bottom__name">
                  {restaurant.name}
                </div>
                <QRCode
                  size={256}
                  bgColor="#6854FF"
                  fgColor="#ffffff"
                  style={{
                    height: "auto",
                    maxWidth: "172px",
                    width: "100%",
                  }}
                  value={`https://wubalu.com/upload-story/${restaurant.slug}?utm_source=${restaurant.slug}&utm_content=qr3`}
                  viewBox={`0 0 256 256`}
                />
                <div className="RestaurantDisplays__qr__bottom__logo">
                  <img alt="wubalu" src={LogoImg} />
                </div>
              </div>
            </div>
            <div className="RestaurantDisplays__qr__download">
              <Button variant="contained" onClick={() => downloadQR(qrRef3)}>
                Descargar
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RestaurantDisplays;
