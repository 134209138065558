import * as yup from "yup";

export function buildValidationSchema() {
  return yup.object().shape({
    // name: yup.string().label("Nombre de campaña").required("Campo requerido"),
    influencer_prize: yup
      .string()
      .label("Premio por story")
      .required("Campo requerido"),
  });
}
