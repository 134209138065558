import { useEffect } from "react";
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Tooltip as MuiTooltip,
} from "@mui/material";
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Doughnut, Line, Bar } from "react-chartjs-2";
import { format } from "date-fns";

import useRestaurants from "../../models/restaurants/useRestaurants";
import { InfoOutlined } from "@mui/icons-material";
import { useMemo } from "react";
import { useState } from "react";
import AutocompleteInput from "../../components/Autocomplete";

import "./styles.scss";

const tooltipData = [
  { followersRange: "0 - 200", percentage: "70%" },
  { followersRange: "201 - 500", percentage: "50%" },
  { followersRange: "501 - 1,000", percentage: "40%" },
  { followersRange: "1,001 - 5,000", percentage: "30%" },
  { followersRange: "5,001 - 10,000", percentage: "22.5%" },
  { followersRange: "10,001 - 50,000", percentage: "15%" },
  { followersRange: "50,001 - 100,000", percentage: "10%" },
  { followersRange: "100,001 - 500,000", percentage: "6.5%" },
  { followersRange: "500,001 - 1,000,000", percentage: "4.5%" },
  { followersRange: "Más de 1,000,000", percentage: "3%" },
];

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = () => {
  const {
    restaurantAnalytics,
    restaurantList,
    fetchAnalytics,
    fetchRestaurantList,
  } = useRestaurants();
  const [restaurant, setRestaurant] = useState({ label: "Todos", value: "" });

  const data = {
    labels: ["Aceptadas", "Rechazadas"],
    datasets: [
      {
        label: "Nº stories",
        data: [
          restaurantAnalytics.number_of_validated_stories,
          restaurantAnalytics.number_of_rejected_stories,
        ],
        backgroundColor: ["rgba(75, 192, 192, 0.2)", "rgba(255, 99, 132, 0.2)"],
        borderColor: ["rgba(75, 192, 192, 1)", "rgba(255, 99, 132, 1)"],
        borderWidth: 1,
      },
    ],
  };

  const barddata = {
    labels: (restaurantAnalytics?.active_stories_last_24_hours || []).map(
      (item) => `${new Date(item[0]).getHours()}:00`
    ),
    datasets: [
      {
        label: "Nº stories",
        data: (restaurantAnalytics?.active_stories_last_24_hours || []).map(
          (item) => item[2]
        ),
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: null,
      title: null,
    },
    scales: {
      y: {
        ticks: {
          precision: 0,
        },
      },
    },
  };

  const doughnutOptions = {
    plugins: {
      legend: null,
      title: null,
    },
  };

  const lineoptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: null,
      title: null,
    },
    scales: {
      y: {
        ticks: {
          precision: 0,
        },
      },
    },
  };

  const dataline = {
    labels: (restaurantAnalytics?.valid_stories_last_30_days || []).map(
      (item) => format(new Date(item[0]), "dd/MM")
    ),
    datasets: [
      {
        label: "Periodo actual",
        data: (restaurantAnalytics?.valid_stories_last_30_days || []).map(
          (item) => item[2]
        ),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 1)",
      },
      {
        label: "Periodo anterior",
        data: (restaurantAnalytics?.valid_stories_previous_30_days || []).map(
          (item) => item[2]
        ),
        borderDash: [5, 5],
        borderColor: "rgba(53, 162, 235, 0.5)",
        backgroundColor: "white",
      },
    ],
  };

  const restaurantOptions = useMemo(() => {
    return [
      { label: "Todos", value: "" },
      ...(restaurantList || []).map((item) => ({
        label: item.name,
        value: item.id,
      })),
    ];
  }, [restaurantList]);

  useEffect(() => {
    fetchAnalytics(restaurant.value);
  }, [restaurant]);

  useEffect(() => {
    fetchRestaurantList();
  }, []);

  return (
    <div className="Dashboard">
      <div className="Dashboard__header">
        <h1>Dashboard</h1>
        <div className="Dashboard__select-restaurant">
          <AutocompleteInput
            value={restaurant}
            options={restaurantOptions}
            onChange={setRestaurant}
          />
        </div>
      </div>
      <div className="Dashboard__cards">
        <div className="Dashboard__kpis">
          <div className="Dashboard__kpis__row">
            <Card elevation={0} className="Dashboard__kpi">
              <CardContent>
                <div className="Dashboard__kpi__value">
                  {restaurantAnalytics?.number_of_validated_stories}
                </div>
                <div className="Dashboard__kpi__label">Stories subidas</div>
              </CardContent>
            </Card>
            <Card elevation={0} className="Dashboard__kpi">
              <CardContent>
                <MuiTooltip
                  title={
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <Typography variant="subtitle2">
                              Número de Seguidores
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle2">
                              Porcentaje Estimado de Visualizaciones
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tooltipData.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell>{row.followersRange}</TableCell>
                            <TableCell>{row.percentage}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  }
                  placement="bottom"
                  arrow
                  enterDelay={500}
                >
                  <InfoOutlined className="Dashboard__kpi__tooltip" />
                </MuiTooltip>
                <div className="Dashboard__kpi__value">
                  {restaurantAnalytics?.estimated_reach &&
                    Intl.NumberFormat("en", { notation: "compact" }).format(
                      restaurantAnalytics?.estimated_reach
                    )}
                </div>
                <div className="Dashboard__kpi__label">
                  Impresiones estimadas
                </div>
              </CardContent>
            </Card>
          </div>
          <div className="Dashboard__kpis__row">
            <Card elevation={0} className="Dashboard__kpi">
              <CardContent>
                <div className="Dashboard__kpi__value">
                  {restaurantAnalytics?.unique_users}
                </div>
                <div className="Dashboard__kpi__label">Usuarios únicos</div>
              </CardContent>
            </Card>
            <Card elevation={0} className="Dashboard__kpi">
              <CardContent>
                <div className="Dashboard__kpi__value">
                  {restaurantAnalytics?.recurrence_rate &&
                    parseFloat(restaurantAnalytics?.recurrence_rate).toFixed(2)}
                </div>
                <div className="Dashboard__kpi__label">Tasa de recurrencia</div>
              </CardContent>
            </Card>
          </div>
        </div>
        <Card elevation={0} className="Dashboard__active-stories__content">
          <CardContent>
            <Typography
              sx={{
                fontSize: 12,
                fontFamily: "dm sans",
                color: "#CCCCCC",
                marginBottom: "8px",
              }}
              color="text.secondary"
            >
              Stories activas
            </Typography>
            <div className="Dashboard__active-stories">
              Total:{" "}
              <span className="Dashboard__active-stories__value">
                {restaurantAnalytics?.active_stories_last_24_hours?.reduce(
                  (acumulador, item) => acumulador + item[2],
                  0
                )}
              </span>
            </div>
            <div className="Dashboard__active-stories__chart">
              <Bar data={barddata} options={options} />
            </div>
          </CardContent>
        </Card>
        <Card elevation={0} className="Dashboard__validated">
          <CardContent>
            <Typography
              sx={{
                fontSize: 12,
                fontFamily: "dm sans",
                color: "#CCCCCC",
                marginBottom: "8px",
              }}
              color="text.secondary"
            >
              Validación stories
            </Typography>
            <div className="Dashboard__validated__legend">
              <div className="Dashboard__validated__legend__item">
                <div className="Dashboard__validated__legend__item__bullet Dashboard__validated__legend__item__bullet--validated" />
                <div>Validadas</div>
              </div>
              <div className="Dashboard__validated__legend__item">
                <div className="Dashboard__validated__legend__item__bullet Dashboard__validated__legend__item__bullet--rejected" />
                <div>Rechazadas</div>
              </div>
            </div>
            <div className="Dashboard__validated__chart">
              <div>
                <Doughnut data={data} options={doughnutOptions} />
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
      <div className="Dashboard__cards">
        <Card elevation={0} className="Dashboard__stories">
          <CardContent>
            <Typography
              sx={{
                fontSize: 12,
                fontFamily: "dm sans",
                color: "#CCCCCC",
                marginBottom: "8px",
              }}
              color="text.secondary"
            >
              Stories validadas los últimos 30 días
            </Typography>
            <div className="Dashboard__total-stories">
              <div className="Dashboard__total-stories__title">
                <div className="Dashboard__total-stories__legend" />
                Periodo actual:{" "}
                <span className="Dashboard__total-stories__value">
                  {restaurantAnalytics?.valid_stories_last_30_days?.reduce(
                    (acumulador, item) => acumulador + item[2],
                    0
                  )}
                </span>
              </div>
              <div className="Dashboard__total-stories__title">
                <div className="Dashboard__total-stories__legend Dashboard__total-stories__legend--dashed" />
                Periodo anterior:{" "}
                <span className="Dashboard__total-stories__value">
                  {restaurantAnalytics?.valid_stories_previous_30_days?.reduce(
                    (acumulador, item) => acumulador + item[2],
                    0
                  )}
                </span>
              </div>
            </div>
            <div className="Dashboard__stories__chart">
              <Line options={lineoptions} data={dataline} />;
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default Dashboard;
