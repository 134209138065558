import { RotatingLines } from "react-loader-spinner";

import useUi from "../../models/ui/useUi";

import "./styles.scss";

const Loader = () => {
  const { isLoaderVisible } = useUi();
  return (
    isLoaderVisible && (
      <div className="Loader">
        <RotatingLines
          height="80"
          width="80"
          radius="9"
          strokeColor="white"
          wrapperStyle
          wrapperClass
        />
      </div>
    )
  );
};

export default Loader;
