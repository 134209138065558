import { Typography } from "@mui/material";

import "./styles.scss";

const Footer = () => {
  return (
    <div className="Footer">
      <Typography textAlign="center">
        © 2024 Wubalu. Todos los derechos reservados.
      </Typography>
    </div>
  );
};

export default Footer;
