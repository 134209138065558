import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useInvitations from "../../models/invitations/useInvitations";
import { toast } from "react-toastify";
import { Checkbox, FormControlLabel } from "@mui/material";

export default function InvitationModal({ isOpen, onClose }) {
  const { sendInvitation } = useInvitations();
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        component: "form",
        onSubmit: async (event) => {
          event.preventDefault();
          try {
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
            const is_admin = !!formJson.is_admin;
            await sendInvitation({ email, is_admin });
            toast.success(`Invitación enviada a ${email}`);
            onClose();
          } catch (e) {
            toast.error("UPS, no hemos podido enviar la invitación.");
          }
        },
      }}
    >
      <DialogTitle>Invitar trabajador</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Añade el email del trabajador al que deseas invitar.
        </DialogContentText>
        <TextField
          autoFocus
          required
          margin="dense"
          id="name"
          name="email"
          label="Email"
          type="email"
          fullWidth
          variant="standard"
        />
        <FormControlLabel
          control={<Checkbox name="is_admin" />}
          label="Invitar como administrador"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancelar
        </Button>
        <Button type="submit" variant="contained">
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
