import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import useAccountCampaigns from "../../../../models/accountCampaigns/useAccountCampaigns";
import useRestaurants from "../../../../models/restaurants/useRestaurants";
import CampaignForm from "./Form";
import PaymentInfoModal from "../../../../containers/PaymentInfoModal";

import "./styles.scss";

const RestaurantCampaigns = () => {
  const { restaurant, activateCampaign, deactivateCampaign, fetchRestaurant } =
    useRestaurants();
  const { campaignList, fetchCampaignList } = useAccountCampaigns();
  const [showModal, setShowModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [onPaymentSucceed, setOnPaymentSucceed] = useState(null);

  const initCampaignList = async () => {
    await fetchCampaignList({ exclude_restaurant_id: restaurant.id });
  };

  const handleActivateCampaign = async (campaign, type) => {
    try {
      await activateCampaign(restaurant.slug, campaign, type);
      await fetchRestaurant(restaurant.slug);
      await initCampaignList();
      toast.success("Recompensa activada correctamente.");
    } catch (e) {
      if (e.status === 402) {
        setShowPaymentModal(true);
        setOnPaymentSucceed(() => {
          return () => {
            handleActivateCampaign(campaign, type);
          };
        });
      } else {
        toast.error("Error al activar la recompensa.");
      }
    }
  };

  const handleDeactivateCampaign = async (campaign, type) => {
    try {
      await deactivateCampaign(restaurant.slug, campaign, type);
      await fetchRestaurant(restaurant.slug);
      await initCampaignList();
      toast.success("Recompensa desactivada correctamente.");
    } catch (e) {
      toast.error("Error al desactivar la recompensa.");
    }
  };

  useEffect(() => {
    initCampaignList();
  }, []);

  return (
    <>
      <div className="RestaurantCampaigns">
        <div className="RestaurantCampaigns__header">
          <div className="RestaurantCampaigns__title">
            Listado de recompensas
          </div>
        </div>
        <div className="RestaurantCampaigns__text">
          Configura a que recompensas puede optar el cliente por subir una story
          a través de nuestro proceso. Solo pueden elegir una recompensa por
          cada participación. Puedes configurar la frecuencia de participación
          en el apartado información general del restaurante.
        </div>
        <div className="RestaurantCampaigns__container">
          <div className="RestaurantCampaigns__list">
            <div className="RestaurantCampaigns__list__header">
              <div className="RestaurantCampaigns__list__name">
                Recompensas activas
              </div>
              <div>
                <Button variant="contained" onClick={() => setShowModal(true)}>
                  Añadir recompensa
                </Button>
              </div>
            </div>
            {(restaurant?.account_campaigns || []).map((item) => (
              <div className="RestaurantCampaigns__campaign" key={item.id}>
                <div className="RestaurantCampaigns__campaign__prize">
                  {item.influencer_prize}
                </div>
                <Button
                  variant="outlined"
                  onClick={() => handleDeactivateCampaign(item.id, "account")}
                >
                  Desactivar en este restaurante
                </Button>
              </div>
            ))}
            {(restaurant?.brand_campaigns || []).map((item) => (
              <div
                className="RestaurantCampaigns__campaign RestaurantCampaigns__campaign--brand"
                key={item.id}
              >
                <div className="RestaurantCampaigns__campaign__name">
                  <span>Campaña: </span> {item.name}
                </div>
                <div className="RestaurantCampaigns__campaign__prize">
                  <span>Recompensa: </span> {item.influencer_prize}
                </div>
                <Button
                  variant="outlined"
                  onClick={() => handleDeactivateCampaign(item.id, "brand")}
                >
                  Desactivar en este restaurante
                </Button>
              </div>
            ))}
          </div>
          <div className="RestaurantCampaigns__list">
            <div className="RestaurantCampaigns__list__header">
              <div className="RestaurantCampaigns__list__name">
                Recompensas disponibles
              </div>
            </div>
            {(campaignList || []).map((item) => (
              <div className="RestaurantCampaigns__campaign" key={item.id}>
                <div className="RestaurantCampaigns__campaign__prize">
                  {item.influencer_prize}
                </div>
                <Button
                  variant="contained"
                  onClick={() => handleActivateCampaign(item.id, "account")}
                >
                  Activar en este restaurante
                </Button>
              </div>
            ))}
          </div>
        </div>
      </div>
      {showModal && (
        <CampaignForm
          onClose={() => setShowModal(false)}
          isOpen={showModal}
          onActivateCampaign={handleActivateCampaign}
        />
      )}
      {showPaymentModal && (
        <PaymentInfoModal
          onClose={() => {
            setShowPaymentModal(null);
            fetchRestaurant(restaurant.slug);
            initCampaignList();
          }}
          onPaymentSucceed={onPaymentSucceed}
        />
      )}
    </>
  );
};

export default RestaurantCampaigns;
