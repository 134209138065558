import React, { useState } from "react";

import closed from "./images/closed.svg";
import open from "./images/open.svg";
import "./styles.scss";

const Accordion = ({ items }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(index === openIndex ? null : index);
  };

  return (
    <div className="Accordion">
      {items.map((item, index) => (
        <div className="Accordion__item" key={index}>
          <div
            className="Accordion__question"
            onClick={() => handleToggle(index)}
          >
            <div>{item.question}</div>
            <div>
              <img src={openIndex === index ? open : closed} />
            </div>
          </div>
          <div
            className={`Accordion__answer ${
              openIndex === index ? "open" : "closed"
            }`}
            style={{
              height: openIndex === index ? "auto" : 0,
              overflow: "hidden",
              transition: "height 0.3s ease",
            }}
          >
            <p>{item.answer}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
