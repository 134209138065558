import "./styles.scss";

const PriceChart = () => {
  return (
    <div className="PriceChart">
      <div className="PriceChart__tier">
        <div className="PriceChart__bar" />
        <div className="PriceChart__content">
          <div className="PriceChart__range">
            De <b>0 a 10 stories</b>
          </div>
          <div className="PriceChart__price">Gratis</div>
        </div>
      </div>

      <div className="PriceChart__tier">
        <div className="PriceChart__bar" />
        <div className="PriceChart__content">
          <div className="PriceChart__range">
            De <b>11 a 100 stories</b>
          </div>
          <div className="PriceChart__price">
            <span className="PriceChart__price__total">Total </span>50 €
          </div>
          <div className="PriceChart__vat">+ IVA</div>
        </div>
      </div>

      <div className="PriceChart__tier">
        <div className="PriceChart__bar" />
        <div className="PriceChart__content">
          <div className="PriceChart__range">
            De <b>101 a 250 stories</b>
          </div>
          <div className="PriceChart__price">
            <span className="PriceChart__price__total">Total </span>100 €
          </div>
          <div className="PriceChart__vat">+ IVA</div>
        </div>
      </div>

      <div className="PriceChart__tier">
        <div className="PriceChart__bar" />
        <div className="PriceChart__content">
          <div className="PriceChart__range">
            De <b>251 a 500 stories</b>
          </div>
          <div className="PriceChart__price">
            <span className="PriceChart__price__total">Total </span>150 €
          </div>
          <div className="PriceChart__vat">+ IVA</div>
        </div>
      </div>

      <div className="PriceChart__tier">
        <div className="PriceChart__bar" />
        <div className="PriceChart__content">
          <div className="PriceChart__range">
            De <b>501 a infinito</b>
          </div>
          <div className="PriceChart__price">
            <span className="PriceChart__price__total">Total </span>200 €
            <div className="PriceChart__extra"> + 0,15€/story</div>
          </div>
          <div className="PriceChart__vat">+ IVA</div>
        </div>
      </div>
    </div>
  );
};

export default PriceChart;
