import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import classNames from "classnames";
import Footer from "../Footer";
import Header from "../Header";

import "react-toastify/dist/ReactToastify.css";
import "./styles.scss";
import Loader from "../Loader";

const AdminLayout = () => {
  const location = useLocation();
  const [isHidden, setIsHidden] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const isFullWidth = ["/what-is-wubalu"].includes(location.pathname);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY < 70) return;
      if (currentScrollY > lastScrollY) {
        // Scroll hacia abajo
        setIsHidden(true);
      } else {
        // Scroll hacia arriba
        setIsHidden(false);
      }

      setLastScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  return (
    <>
      <div className="AdminLayout">
        <div
          className={classNames("AdminLayout__header", {
            "AdminLayout__header--hidden": isHidden,
          })}
        >
          <Header />
        </div>
        <div
          className={classNames("AdminLayout__main", {
            "AdminLayout__main--fullwidth": isFullWidth,
          })}
        >
          <Outlet />
        </div>
        <div className="AdminLayout__footer">
          <Footer />
        </div>
      </div>
      <Loader />
      <ToastContainer />
    </>
  );
};

export default AdminLayout;
