import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import AndroidButton from "../../components/appButtons/AndroidButton";
import AppleButton from "../../components/appButtons/AppleButton";
import banner from "./images/instructions.png";
import img1 from "./images/step1.png";
import img2 from "./images/step2.png";
import img3 from "./images/step3.png";
import img4 from "./images/step4.png";

import "./styles.scss";

const Instructions = () => {
  return (
    <div className="Instructions">
      <h1>¿Qué es Wubalu?</h1>
      <div className="Instructions__banner">
        <img src={banner} />
        <div className="Instructions__banner__text">
          Wubalu es un sistema con el que los clientes pueden subir una{" "}
          <span>story de Instagram a cambio de un premio.</span>
        </div>
      </div>
      <div className="Instructions__apps">
        <div className="Instructions__apps__title">
          ¿Todavía no tienes la app?
        </div>
        <div className="Instructions__apps__text">
          Descárgate nuestras apps para validar desde tu móvil sin necesidad de
          hacer login cada vez. Es mucho más fácil y cómodo.
        </div>
        <div className="Instructions__apps__buttons">
          <AndroidButton />
          <AppleButton />
        </div>
      </div>
      <h1>Instrucciones de uso</h1>
      <div className="Instructions__steps">
        <div className="Instructions__step Instructions__step--1">
          <div className="Instructions__step__number">Paso 1</div>
          <div>
            <img src={img1} />
          </div>
          <div className="Instructions__step__title">
            Inicio de Sesión Wubalu
          </div>
          <div className="Instructions__step__text">
            Accede y identifícate en la web de administración
          </div>
          <div>https://restaurants.wubalu.com/admin</div>
        </div>
        <div className="Instructions__step Instructions__step--2">
          <div className="Instructions__step__number">Paso 2</div>
          <div>
            <img src={img2} />
          </div>
          <div className="Instructions__step__title">
            Escanear el QR del Cliente
          </div>
          <div className="Instructions__step__text">
            Accede a la sección "Validador QR", elige el restaurante en el que
            estás y escanea el código que el cliente te muestra.
          </div>
          <div>
            <div>Posibles resultados al escanear:</div>
            <ul>
              <li>
                <b>
                  <u>VÁLIDO:</u>
                </b>{" "}
                Se mostrará el premio que hay que dar al cliente.
              </li>
              <li>
                <b>
                  <u>NO VÁLIDO:</u>
                </b>{" "}
                QR ya validado o rechazado previamente, el cliente no tiene
                ninguna recompensa.
              </li>
            </ul>
          </div>
        </div>
        <div className="Instructions__step Instructions__step--4">
          <div className="Instructions__step__number">Paso 3</div>
          <div>
            <img src={img4} />
          </div>
          <div className="Instructions__step__title">Entrega del premio</div>
          <div>
            <div>
              Si el QR es válido, entrega el premio al cliente de forma gratuita
              según lo indicado en la pantalla de validación.
            </div>
          </div>
        </div>
        <div className="Instructions__step Instructions__step--5">
          <div className="Instructions__step__title">Consejos</div>
          <ul>
            <li>
              <b>
                <u>Revisar detenidamente:</u>
              </b>{" "}
              Asegúrate de revisar los requisitos antes de leer el QR.
            </li>
            <li>
              <b>
                <u>Cortesía:</u>
              </b>{" "}
              Mantén siempre una actitud profesional.
            </li>
            <li>
              <b>
                <u>Ayuda:</u>
              </b>{" "}
              Contacta al administrador si tienes problemas.
            </li>
          </ul>
        </div>
        <div className="Instructions__cta">
          <Link to="../story-validation">
            <Button variant="contained">Empezar a validar</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Instructions;
