import { useDispatch, useSelector } from "react-redux";
import useFetch from "../../utils/useFetch";
import { setDetail, setList, clearDetail } from "./store";

const useStories = () => {
  const { detail, list } = useSelector((state) => state.stories);
  const dispatch = useDispatch();
  const { post, get } = useFetch();
  const endpoint = "stories";

  const fetchList = async (params) => {
    const response = await get({
      endpoint,
      isPrivate: true,
      params,
    });
    response &&
      dispatch(
        setList({
          items: response.results,
          count: response.count,
          page: params.page,
        })
      );
  };

  const fetchDetail = async (id) => {
    const response = await get({
      endpoint: `${endpoint}/${id}`,
      isPrivate: true,
    });
    response && dispatch(setDetail(response));
  };

  const validate = async (id) => {
    return await post({ endpoint: `${endpoint}/${id}/validate`, body: {} });
  };

  const reject = async (id) => {
    return await post({ endpoint: `${endpoint}/${id}/reject`, body: {} });
  };

  return {
    story: detail,
    storyList: list,
    validateStory: validate,
    rejectStory: reject,
    fetchStory: fetchDetail,
    fetchStories: fetchList,
    clearStory: () => dispatch(clearDetail()),
  };
};

export default useStories;
