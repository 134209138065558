import { useMemo, useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import Button from "@mui/material/Button";
import PriceChart from "../../PriceChart";
import useAccounts from "../../../models/accounts/useAccounts";
import useRestaurants from "../../../models/restaurants/useRestaurants";

import "./styles.scss";
import { toast } from "react-toastify";

const PaymentMethod = ({ onClose, onPaymentSucceed }) => {
  const { createSetupIntent, setPaymentMethod } = useAccounts();
  const { restaurant } = useRestaurants();
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const hasDiscount = useMemo(
    () => restaurant.price_rate !== "STANDARD",
    [restaurant]
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const cardElement = elements.getElement(CardElement);

    const { client_secret } = await createSetupIntent();

    // Confirmar el Setup Intent en el frontend
    const { error, setupIntent } = await stripe.confirmCardSetup(
      client_secret,
      {
        payment_method: {
          card: cardElement,
        },
      }
    );

    if (error) {
      setErrorMessage(error.message);
    } else if (setupIntent.status === "succeeded") {
      try {
        await setPaymentMethod({ payment_method: setupIntent.payment_method });
        toast.success("Método de pago añadido correctamente.");
        setLoading(false);
        setErrorMessage(null);
        onPaymentSucceed();
        onClose();
      } catch (e) {
        toast.error("Ha ocurrido un error inesperado.");
        setLoading(false);
      }
    }
    setLoading(false);
  };

  return (
    <div className="PaymentMethod">
      <div className="PaymentInfoModal__title">Método de pago</div>
      {hasDiscount && (
        <div className="PaymentMethod__discount">
          Disfruta de {restaurant.price_rate === "30_DAYS_TRIAL" ? 30 : 60} días
          gratis por tu código descuento.
        </div>
      )}
      <div className="PaymentInfoModal__subtitle">
        Suscripción mensual en función del uso por restaurante.
      </div>
      <div>
        <PriceChart />
      </div>
      <div className="PaymentMethod__read-more">
        <a href="https://restaurants.wubalu.com/pricing" target="_blank">
          Más información acerca de nuestras tarifas.
        </a>
      </div>
      <div className="PaymentInfoModal__subtitle">
        Completa tus datos de pago
      </div>
      <div className="PaymentMethod__card">
        <CardElement
          options={{
            style: {
              base: {
                fontSize: "16px",
                color: "#424770",
                "::placeholder": {
                  color: "#aab7c4",
                },
              },
              invalid: {
                color: "#9e2146",
              },
            },
          }}
        />
      </div>
      {errorMessage && (
        <div className="PaymentMethod__error">{errorMessage}</div>
      )}
      <div>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={!stripe || loading}
        >
          Guardar y finalizar
        </Button>
      </div>
    </div>
  );
};

export default PaymentMethod;
