import { Controller } from "react-hook-form";
import TextInput from "./index";

const FormTextInput = ({
  control,
  name,
  label,
  required,
  placeholder,
  disabled,
  type = "text",
  helperText,
  onChange,
  multiline,
}) => {
  const handleChange = ({ name, value, onFieldChange }) => {
    onFieldChange(value);
    onChange && onChange(name, value);
  };
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <TextInput
          label={label ?? ""}
          required={required}
          placeholder={placeholder}
          name={field.name}
          value={field.value ?? ""}
          type={type}
          helperText={helperText}
          onChange={(value) =>
            handleChange({
              value,
              name: field.name,
              onFieldChange: field.onChange,
            })
          }
          error={fieldState?.error?.message}
          disabled={disabled}
          multiline={multiline}
        />
      )}
    />
  );
};

export default FormTextInput;
