import { yupResolver } from "@hookform/resolvers/yup";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Button,
  Tooltip as MuiTooltip,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { InfoOutlined } from "@mui/icons-material";
import { useState } from "react";
import { toast } from "react-toastify";

import { buildValidationSchema } from "./validations";
import FormTextInput from "../../../../../components/TextInput/Form";
import useAccountCampaigns from "../../../../../models/accountCampaigns/useAccountCampaigns";

import "./styles.scss";

const CampaignForm = ({ campaign, onClose, isOpen, onActivateCampaign }) => {
  const { createCampaign } = useAccountCampaigns();

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(buildValidationSchema()),
    defaultValues: {
      name: campaign?.name || "",
      influencer_prize: campaign?.influencer_prize || "",
    },
  });

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { isValid, isDirty, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      const newCampaign = await createCampaign({
        influencer_prize: data.influencer_prize,
        name: data.influencer_prize,
      });
      toast.success("Recompensa creada correctamente.");
      onActivateCampaign(newCampaign.id, "account");
      onClose();
    } catch (e) {
      toast.success("Error al crear la recompensa.");
    }
  };

  return (
    <Dialog open={isOpen} className="CampaignForm" onClose={onClose}>
      <DialogTitle>Añadir recompensa</DialogTitle>
      <FormProvider {...methods} autoComplete={false}>
        <DialogContent>
          <DialogContentText>
            ¿Que premio quieres ofrecerle a tus clientes?
          </DialogContentText>
          <div className="CampaignForm__inputs">
            <div>
              <div className="CampaignForm__label">Recompensa por story</div>
              <FormTextInput
                control={control}
                name="influencer_prize"
                placeholder="Ej, Tapa de bravas"
                required={true}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="CampaignForm__actions">
            <Button variant="outlined" onClick={onClose}>
              Cancelar
            </Button>
            <Button
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              disabled={!isValid || !isDirty || isSubmitting}
            >
              Guardar
            </Button>
          </div>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};

export default CampaignForm;
