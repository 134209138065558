import { Button } from "@mui/material";
import useAuth from "../../models/auth/useAuth";

import "./styles.scss";

const Unauthorized = () => {
  const { logout } = useAuth();

  return (
    <div className="Unauthorized">
      <h2>No tienes permisos para ver esta página.</h2>
      <div>Por favor, ponte en contacto con tu administrador.</div>
      <div>
        <Button variant="outlined" onClick={logout}>
          Cerrar sesión
        </Button>
      </div>
    </div>
  );
};

export default Unauthorized;
