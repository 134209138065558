import { DataGrid } from "@mui/x-data-grid";

import "./styles.scss";

const Table = ({
  columns,
  data,
  page = 1,
  count,
  pageSize,
  pageSizeOptions = [10, 20, 50],
  hidePagination = false,
  onChangePage,
  onChangePageSize,
}) => {
  return (
    <DataGrid
      disableColumnFilter
      disableColumnMenu
      rows={data}
      columns={columns}
      hideFooter={hidePagination}
      disableColumnResize={true}
      initialState={{
        pagination: {
          paginationModel: { page, pageSize: 50 },
        },
      }}
      rowSelection={false}
      componentsProps={{
        pagination: {
          page: (page || 0) - 1,
          rowsPerPage: pageSize,
          count,
          onPageChange: (_, page) => {
            onChangePage && onChangePage(page + 1);
          },
          onRowsPerPageChange: (e) => {
            onChangePageSize && onChangePageSize(parseInt(e.target.value));
          },
          labelRowsPerPage: "Elementos por página",
          labelDisplayedRows: (paginationInfo) => {
            return `${paginationInfo.from} - ${paginationInfo.to} de ${paginationInfo.count}`;
          },
        },
      }}
      pageSizeOptions={pageSizeOptions}
    />
  );
};

export default Table;
