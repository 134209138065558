import card1 from "./images/card1.png";
import card2 from "./images/card2.png";
import card3 from "./images/card3.png";
import card4 from "./images/card4.png";
import card5 from "./images/card5.png";
import card6 from "./images/card6.png";

import "./styles.scss";

const HowItWorks = () => {
  return (
    <div className="HowItWorks">
      <div className="HowItWorks__title">¿Cómo funciona?</div>
      <div className="HowItWorks__cards">
        <div className="HowItWorks__card HowItWorks__card--1">
          <div>
            <div className="HowItWorks__card__title">
              Configura tu restaurante
            </div>
            <div className="HowItWorks__card__text">
              Define los requisitos para participar y la recompensa que van a
              recibir tus clientes a cambio de una story.
            </div>
          </div>
          <div className="HowItWorks__card__image">
            <img src={card1} />
          </div>
        </div>
        <div className="HowItWorks__card HowItWorks__card--2">
          <div>
            <div className="HowItWorks__card__title">
              Invita a tus clientes a participar
            </div>
            <div className="HowItWorks__card__text">
              Enseña a los clientes el QR que nosotros te preparamos.
            </div>
          </div>
          <div className="HowItWorks__card__image">
            <img src={card2} />
          </div>
        </div>
        <div className="HowItWorks__card HowItWorks__card--3">
          <div>
            <div className="HowItWorks__card__title">
              Los clientes participan
            </div>
            <div className="HowItWorks__card__text">
              Subiendo una story a Instagram siguiendo los requisitos definidos.
            </div>
          </div>
          <div className="HowItWorks__card__image">
            <img src={card3} />
          </div>
        </div>
        <div className="HowItWorks__card HowItWorks__card--4">
          <div>
            <div className="HowItWorks__card__title">Validación de story</div>
            <div className="HowItWorks__card__text">
              La story se valida con inteligencia artificial. Si todo es
              correcto, generará un código QR para enseñar a un trabajador.
            </div>
          </div>
          <div className="HowItWorks__card__image">
            <img src={card4} />
          </div>
        </div>
        <div className="HowItWorks__card HowItWorks__card--5">
          <div>
            <div className="HowItWorks__card__title">Entrega la recompensa</div>
            <div className="HowItWorks__card__text">
              El trabajador valida que el QR del cliente es válido a través de
              la app de Wubalu. Si el QR es válido mostrará el premio a
              entregar.
            </div>
          </div>
          <div className="HowItWorks__card__image">
            <img src={card5} />
          </div>
        </div>
        <div className="HowItWorks__card HowItWorks__card--6">
          <div>
            <div className="HowItWorks__card__title">¡A volar!</div>
            <div className="HowItWorks__card__text">
              Por último, pero no menos importante, disfruta en tiempo real de
              tu salto a la fama desde nuestro dashboard.
            </div>
          </div>
          <div className="HowItWorks__card__image">
            <img src={card6} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
