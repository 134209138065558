import { Button } from "@mui/material";
import { toast } from "react-toastify";
import useAuth from "../../models/auth/useAuth";

const DeactivateAccount = () => {
  const { deactivateAccount, me, logout } = useAuth();

  const handleDeactivate = async () => {
    try {
      await deactivateAccount();
      logout();
      toast.success("Cuenta eliminada correctamente.");
    } catch (e) {
      toast.error("Ups, ha ocurrido un error.");
    }
  };

  return (
    <div className="DeactivateAccount">
      <div>
        <h2>Eliminación de Datos de Usuario</h2>
        <div>
          Aquí eliminaras tu cuenta junto a todos tus datos, este proceso es
          irreversible.
        </div>
        <Button variant="contained" onClick={handleDeactivate}>
          Eliminar cuenta
        </Button>
      </div>
    </div>
  );
};

export default DeactivateAccount;
