import * as yup from "yup";

export function buildValidationSchema() {
  return yup.object().shape({
    nif: yup.string().label("NIF").required("Campo requerido"),
    company_name: yup
      .string()
      .label("Razón social")
      .required("Campo requerido"),
    street: yup.string().label("Calle").required("Campo requerido"),
    number: yup.string().label("Número").required("Campo requerido"),
    city: yup.string().label("Ciudad").required("Campo requerido"),
    zip_code: yup.string().label("Código postal").required("Campo requerido"),
    province: yup.string().label("Provincia").required("Campo requerido"),
    country: yup.string().label("País").required("Campo requerido"),
    floor: yup.string().label("Planta"),
    apartment: yup.string().label("Puerta"),
  });
}
