const AppleButton = () => {
  return (
    <div className="AppleButton">
      <a href="https://apps.apple.com/us/app/wubalu-admin/id6639620547?itscg=30200&itsct=apps_box_badge&mttnsubad=6639620547">
        <img
          src="https://toolbox.marketingtools.apple.com/api/v2/badges/download-on-the-app-store/black/es-es?releaseDate=1726531200"
          alt="Download on the App Store"
        />
      </a>
    </div>
  );
};
export default AppleButton;
