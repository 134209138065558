import { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { Link, useNavigate } from "react-router-dom";
import { Button, IconButton } from "@mui/material";
import useRestaurants from "../../models/restaurants/useRestaurants";
import Table from "../../components/Table";
import CreateRestaurant from "./Create";

import "./styles.scss";
import RestaurantsEmptyState from "./EmptyState";

const Restaurants = () => {
  const navigate = useNavigate();
  const { restaurantList, fetchRestaurantList } = useRestaurants();
  const [showCreateRestaurant, setShowCreateRestaurant] = useState(false);

  const tableColumns = [
    { headerName: "Nombre", field: "name", flex: 1, sortable: false },
    {
      headerName: "Dirección",
      field: "full_address",
      flex: 4,
      sortable: false,
    },
    {
      headerName: "Nº recompensas activas",
      field: "campaigns_count",
      flex: 1,
      sortable: false,
    },
    {
      headerName: "Activo",
      field: "is_active",
      flex: 1,
      sortable: false,
      renderCell: ({ row }) => (row.is_active ? "Sí" : "No"),
    },
    {
      headerName: "",
      type: "actions",
      field: " ",
      sortable: false,
      renderCell: ({ row }) => (
        <IconButton onClick={() => navigate(row.slug)}>
          <EditIcon />
        </IconButton>
      ),
    },
  ];

  useEffect(() => {
    fetchRestaurantList();
  }, []);

  return (
    <>
      <div className="RestaurantList">
        <div className="RestaurantList__header">
          <h1>Listado de restaurantes</h1>
          {restaurantList.length > 0 && (
            <Link to="create">
              <Button variant="contained">Añadir Restaurante</Button>
            </Link>
          )}
        </div>
        {restaurantList.length > 0 ? (
          <Table columns={tableColumns} data={restaurantList} hidePagination />
        ) : (
          <RestaurantsEmptyState />
        )}
      </div>
      <CreateRestaurant
        isOpen={showCreateRestaurant}
        onClose={() => setShowCreateRestaurant(false)}
      />
    </>
  );
};

export default Restaurants;
