import * as yup from "yup";

export function buildValidationSchema() {
  return yup.object().shape({
    name: yup
      .string()
      .label("Nombre de restaurante")
      .required("Campo requerido"),
    ig_account: yup
      .string()
      .label("Cuenta de Instagram")
      .required("Campo requerido"),
    participation_rate: yup
      .number()
      .integer()
      .positive()
      .min(1, "La frecuencia debe ser mayor que 0")
      .label("Frecuencia de participación")
      .typeError("La frecuencia debe ser un número")
      .required("Campo requerido"),
  });
}
